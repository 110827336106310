html, body {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
}

.header-row {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 25px;
}

.header-row > div {
    margin-bottom: 15px;
}

.logo {
    width: 80%;
    max-width: 80%;
}

.signin-button {
    margin-top: 15px !important;
    height: 45px !important;
}