.company-events {
    display: flex;
    flex: 1;
}

.company-events .content {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.company-events .content .content-list {
    flex: 2;
}

.company-events .content .content-create {
    flex: 1;
}

.company-events .content .content-list,
.company-events .content .content-create {
    border: 1px solid #CCC;
    padding: 0 25px 25px 25px;
}

.company-events .content .content-create {
    border-left: none;
}

    .company-events .content-create.users button {
        margin: 15px 0;
    }

.currency-label {
    padding: 15px 0 8px 0 !important;
}

.company-list-item label {
    margin-right: 15px;
}

.company-list-item.copy label,
.company-list-item.copy svg {
    cursor: pointer;
}

.email-user svg {
    cursor: pointer;
}

.buttons-row {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 20px !important;
}

    .buttons-row button {
        margin-right: 15px;
    }

        .buttons-row button:last-child {
            margin-right: 0;
        }

        .buttons-row .cancel-event-button {
            flex: 1;
        }

        .buttons-row .new-event-button {
            flex: 2;
        }