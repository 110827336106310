html, body, #root {
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
    color: #FFF;
}

#root {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    width: 100%;
}

.footer {
    display: flex;
    background-color: #2e2b40;
    color: #000;
    width: 100%;
    justify-content: center;
    color: #FFF;
    height: 80px;
    align-items: center;
}

    .footer a {
        text-decoration: none;
        color: #FFF;
    }

.fa-spin {
    animation: fa-spin 2s infinite linear;
}
@keyframes fa-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.companies {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: inherit;
    width: inherit;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    margin: 0 !important;
    flex: 1;
}

.content .header {
    display: flex;
    align-items: center;
    background-color: #FFF;
    color: #000;
    padding: 25px;
}

    .header > img {
        height: 60px;
        margin-right: 25px;
    }

    .header-options {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-end;
    }

    .header-options button {
        margin-left: 45px;
    }

.content .header .create-new-company {
    height: 90%;
}

.icon-button {
    cursor: pointer;
}

.company-body {
    display: flex;
    padding: 25px;
    background: url(/img/background.jpg) 50% 0 fixed no-repeat;
    background-size: cover;
    backdrop-filter: blur(15px);
    flex: 1;
    justify-content: center;
}

    .company-body .company-events {
        background-color: #FFF;
    }

    .company-body .company-events p {
        color: #000;
    }

    .company-body .company-events h3 {
        color: #000;
    }

    .blur {
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(8px);
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
    }

    .company-list {
        padding: 25px;
        background-color: #FFF;
        border-radius: 5px;;
        z-index: 99;
        width: 80%;
    }

    .company-list.details {
        border-radius: 0;
        padding: 20px;
        flex: 1;
    }

    .company-list.contact {
        border-radius: 0;
        padding: 20px 20px 20px 0;
        flex: 1;
    }

    .company-list.form {
        padding: 15px;
    }


    .company-list .MuiTableRow-head,
    .content-list .MuiTableRow-head {
        background-color: #2e2b40;
    }

    .company-list .MuiTableRow-head .MuiTableCell-head,
    .content-list .MuiTableRow-head .MuiTableCell-head {
        color: #FFF;
        font-weight: bold;
    }

    .company-list h3 {
        color: #000;
        margin-top: 0;
    }

    .company-list.form .divider {
        margin: 25px 0;
    }

    .company-edit {
        display: flex;
        flex-direction: row;
    }