html, body, #root {
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
}

.dashboard {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: inherit;
    width: inherit;
}

.content {
    padding: 0 15px 15px 15px;
    flex: 1;
}